$(document).ready(function () {
  $('#add-test-data').on('click', function () {

    $('#add-more-ticket').trigger('click');

    $('#add-more-ticket').trigger('click');
    $('#add-more-ticket').trigger('click');
    $('#add-more-ticket').trigger('click');

    $('#form-name').val('Sajt user');
    $('#form-region').val('Sajtország');
    $('#form-email').val('beyatyd@gmail.com');
    $('#form-phone').val('06101231234');
    $('#form-city').val('Sajtváros');
    $('#form-zip').val('1111');
    $('#form-address').val('sajt_utca');

    $('#ticket-label-0-1', document).trigger('click');
    $('#form-name-0', document).val('Sajt name');
    $('#form-email-0', document).val('aasdd@asd.asd');
    $('#form-phone-0', document).val('06101231234');


    $('#ticket-label-1-1', document).trigger('click');
    $('#form-name-1', document).val('Sajt name');
    $('#form-email-1', document).val('aasdd1@asd.asd');
    $('#form-phone-1', document).val('06101231234');


    $('#ticket-label-2-1', document).trigger('click');
    $('#form-name-2', document).val('Sajt name');
    $('#form-email-2', document).val('aasdd2@asd.asd');
    $('#form-phone-2', document).val('06101231234');


    $('#ticket-label-3-1', document).trigger('click');
    $('#form-name-3', document).val('Sajt name');
    $('#form-email-3', document).val('aasdd3@asd.asd');
    $('#form-phone-3', document).val('06101231234');


    setTimeout(function() {
      $('#ticket_x_1', document).trigger('click');
    }, 2000);
  });
});
